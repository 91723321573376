import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const hostDomain = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_HOST_DOMAIN : 'localhost'
let routes = []

if (hostDomain === window.location.hostname) {
  routes = [
    {
      path: '/',
      name: 'Index',
      component: () => import(/* webpackChunkName: "checkout" */ '../views/Index.vue')
    },
    {
      path: '/:storeId/products',
      name: 'Products',
      component: () => import(/* webpackChunkName: "checkout" */ '../views/Products.vue')
    },
    {
      path: '/:storeId/products/:productId',
      name: 'ProductDetails',
      component: () => import(/* webpackChunkName: "checkout" */ '../views/ProductDetails.vue')
    },
    {
      path: '/:storeId/cart/:cartId',
      name: 'Cart',
      component: () => import(/* webpackChunkName: "checkout" */ '../views/Cart.vue')
    },
    {
      path: '/:storeId/c/:cartId',
      name: 'Checkout',
      component: () => import(/* webpackChunkName: "checkout" */ '../views/Checkout.vue')
    },
    {
      path: '/:storeId',
      name: 'Home',
      component: () => import(/* webpackChunkName: "checkout" */ '../views/Home.vue')
    },
    {
      path: '/:storeId/:pageSlug',
      name: 'Page',
      component: () => import(/* webpackChunkName: "checkout" */ '../views/Page.vue')
    }

  ]
} else {
  routes = [
    {
      path: '/',
      name: 'Home',
      component: () => import(/* webpackChunkName: "checkout" */ '../views/Home.vue')
    },
    {
      path: '/products',
      name: 'Products',
      component: () => import(/* webpackChunkName: "checkout" */ '../views/Products.vue')
    },
    {
      path: '/products/:productId',
      name: 'ProductDetails',
      component: () => import(/* webpackChunkName: "checkout" */ '../views/ProductDetails.vue')
    },
    {
      path: '/cart/:cartId',
      name: 'Cart',
      component: () => import(/* webpackChunkName: "checkout" */ '../views/Cart.vue')
    },
    {
      path: '/c/:cartId',
      name: 'Checkout',
      component: () => import(/* webpackChunkName: "checkout" */ '../views/Checkout.vue')
    },
    {
      path: '/:pageSlug',
      name: 'Page',
      component: () => import(/* webpackChunkName: "checkout" */ '../views/Page.vue')
    }
  ]
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
