<template>
  <div class="container" v-loading.fullscreen.lock="appData.id===undefined?true:false">
    <router-view/>
    <footer class="my-5 text-muted fs--1 text-center text-small text-400">
      <p class="mb-1"><a class="text-400" :href="appData.website">{{appData.name}}</a> &copy; {{new Date().getFullYear()}}</p>
      <ul class="list-inline">
        <li class="list-inline-item"><a class="text-400" :href="appData.privacy_policy">Privacy</a></li>
        <li class="list-inline-item"><a class="text-400" :href="appData.terms_of_service">Terms</a></li>
        <li class="list-inline-item">{{storegenceAppVersion}}</li>
      </ul>
    </footer>
  </div>
</template>
<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'App',
  components: {

  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Checkout',
    // all titles will be injected into this template
    titleTemplate: '%s | Powered by Storegence Checkout'
  },
  data: function () {
    return {
      storegenceAppVersion: null,
      appData: {}
    }
  },
  methods: {
    getAppData () {
      const self = this
      // get app details
      self.$storegence.get('/applications/self/details/').then(res => {
        self.appData = res.data
      })
      // production checks
      if (process.env.NODE_ENV === 'production') {
      // set version code
        this.storegenceAppVersion = process.env.VUE_APP_LATEST_TAG === undefined ? null : process.env.VUE_APP_LATEST_TAG
      } else {
        this.storegenceAppVersion = process.env.NODE_ENV
      }
    }
  },
  created () {
    this.getAppData()
  }
}
</script>
