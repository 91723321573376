import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ClientOAuth2 from 'client-oauth2'
import VueCookies from 'vue-cookies'
import axios from 'axios'
import VueAxios from 'vue-axios'
import moment from 'moment'
import numeral from 'numeral'
import numFormat from 'vue-filter-number-format'
import VueIziToast from 'vue-izitoast'
import {
  cacheAdapterEnhancer
  // throttleAdapterEnhancer
} from 'axios-extensions'
import VueMeta from 'vue-meta'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import 'izitoast/dist/css/iziToast.min.css'

Vue.config.productionTip = false

// =================================================================
// constants
// =================================================================
// const debug = false
const apiUrl = process.env.NODE_ENV === 'production' ? 'https://api.storegence.com' : 'https://api.storegence.com'
const identityUrl = process.env.NODE_ENV === 'production' ? 'https://id.storegence.com' : 'https://id.storegence.com'
const siteUrl = process.env.NODE_ENV === 'production' ? 'https://checkout.storegence.com' : 'http://localhost:8080'
const cookieDomain = window.location.hostname
// eslint-disable-next-line no-unused-vars
const hostDomain = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_HOST_DOMAIN : 'localhost'
const subdomainHost = process.env.VUE_APP_SUBDOMAIN_HOST
const localeDateStringOptions = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }

Vue.prototype.$api_url = apiUrl
Vue.prototype.$identity_url = identityUrl
Vue.prototype.$site_url = siteUrl
Vue.prototype.$subdomainHost = subdomainHost

if (!VueCookies.isKey('aT')) {
  Vue.prototype.$storegence_auth = new ClientOAuth2({
    clientId: process.env.VUE_APP_CLIENT_ID,
    clientSecret: process.env.VUE_APP_CLIENT_SECRET,
    accessTokenUri: identityUrl + '/auth/o/token/',
    authorizationUri: identityUrl + '/auth/o/authorize/'
  }).credentials.getToken().then(res => {
    VueCookies.set('aT', res.accessToken, res.expires)
    router.go()
  })
}
// =================================================================
// Vue configs
// =================================================================
Vue.config.productionTip = false
Vue.use(VueAxios, axios)
Vue.use(VueCookies)
Vue.use(VueIziToast)
Vue.use(VueMeta)
Vue.use(ElementUI)
Vue.$cookies.config('90d', '', cookieDomain)
Vue.filter('numFormat', numFormat(numeral))
Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})
Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY hh:mm')
  }
})
Vue.filter('timeString', function (value, type = 'full') {
  if (!value) return ''
  var date = new Date(value)
  if (type === 'full') {
    return date.toString()
  } else if (type === 'date_only') {
    return date.toLocaleDateString('en-US', localeDateStringOptions)
  }
})
// =================================================================
// axios
// =================================================================
Vue.prototype.$storegence = axios.create({
  baseURL: apiUrl,
  params: {
    haseeb: true
  },
  adapter: cacheAdapterEnhancer(axios.defaults.adapter, { enabledByDefault: false }),
  headers: {
    // 'Cache-Control': 'no-cache',
    Authorization: 'Bearer ' + VueCookies.get('aT')
  // 'Content-Type': 'multipart/form-data'
  }
})

Vue.mixin({
  data () {
    return {
      productBlockClass: 'col-xl-2 col-lg-3 col-md-3 col-sm-4 col-6 mb-3',
      placeholderImage: '/assets/placeholder-min.png'
    }
  },
  methods: {
    getCartDetails () {
      var storeId
      if (window.location.hostname.endsWith(subdomainHost)) {
        storeId = localStorage.getItem('storeId')
      } else {
        storeId = this.$route.params.storeId
      }
      return new Promise((resolve, reject) => {
        const self = this
        self.$storegence.get('/sales/carts/' + self.$route.params.cartId + '/?store=' + storeId).then(res => {
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
    getCartItems () {
      var storeId
      if (window.location.hostname.endsWith(subdomainHost)) {
        storeId = localStorage.getItem('storeId')
      } else {
        storeId = this.$route.params.storeId
      }
      return new Promise((resolve, reject) => {
        const self = this
        // get cart items
        self.$storegence.get('/sales/carts/' + self.$route.params.cartId + '/items/?page_size=100&store=' + storeId).then(res => {
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
    getShippingMethods () {
      var storeId
      if (window.location.hostname.endsWith(subdomainHost)) {
        storeId = localStorage.getItem('storeId')
      } else {
        storeId = this.$route.params.storeId
      }
      return new Promise((resolve, reject) => {
        const self = this
        self.$storegence.get('/shipping/methods/?is_active=true&display_on_checkout=true&store=' + storeId).then(res => {
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    getPaymentMethods () {
      var storeId
      if (window.location.hostname.endsWith(subdomainHost)) {
        storeId = localStorage.getItem('storeId')
      } else {
        storeId = this.$route.params.storeId
      }
      return new Promise((resolve, reject) => {
        const self = this
        // get store details
        self.$storegence.get('/payment/methods/?is_active=true&display_on_checkout=true&store=' + storeId).then(res => {
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    getQueriedProducts (query = '') {
      var storeId
      if (window.location.hostname.endsWith(subdomainHost)) {
        storeId = localStorage.getItem('storeId')
      } else {
        storeId = this.$route.params.storeId
      }
      return new Promise((resolve, reject) => {
        const self = this
        // get store products
        self.$storegence.get('/products/product/?is_active=true&page_size=24' + query + '&store=' + storeId, { cache: true }).then(res => {
          // console.log(res.data)
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
    getStoreData (domain = null) {
      return new Promise((resolve, reject) => {
        const self = this
        var url
        if (window.location.hostname.endsWith(subdomainHost)) {
          url = '/stores/public/search/?slug=' + window.location.hostname.slice(0, -14)
        } else {
          url = '/stores/public/' + this.$route.params.storeId + '/'
          if (domain !== null) {
            url = '/stores/public/search/?domain=' + domain
          }
        }
        // get store details
        self.$storegence.get(url, { cache: true }).then(res => {
          self.store = res.data
          localStorage.setItem('storeId', res.data.id)
          self.$cookies.set('storeId', res.data.id)
          document.getElementById('favicon').href = res.data.logo
          // console.log(res.data)
          resolve(res)
        }).catch(err => {
          self.$message({
            showClose: true,
            message: 'Invalid Store',
            type: 'error'
          })
          reject(err)
        })
      })
    }
  },
  created () {
    store.state.aT = true
  }
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
